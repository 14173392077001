import React from 'react'
import Simple from 'layouts/simple'
import Banner from './components/Banner'
import InfoProdutos from './components/InfoProdutos'
import Atencao from './components/Atencao'
import ApresentacaoEmprestimo from './components/ApresentacaoEmprestimo'
import Vantagens from './components/Vantagens'
import EfetuacaoEmprestimo from './components/EfetuacaoEmprestimo'
import Faq from './components/Faq'
import SejaParceiro from './components/SejaParceiro'
import RiberPerto from './components/RiberPerto'
import { ImageFraude } from './style'
import comunicadoFraude from '../../assets/images/comunicado-fraude.png'

const LandingPage = () => {
  return (
    <Simple>
      <Banner />
      <InfoProdutos />
      <Atencao />
      <ImageFraude src={comunicadoFraude} />
      <ApresentacaoEmprestimo />
      <Vantagens />
      <RiberPerto />
      <EfetuacaoEmprestimo />
      <Faq />
      <SejaParceiro />
    </Simple>
  )
}

export default LandingPage
