import styled from 'styled-components'
import backgroundBanner from 'assets/images/background-verde.png'
import landing1 from 'assets/images/landing1.png'
import backgroundFaq from 'assets/images/background-verde.png'
import personaFaq from 'assets/images/personaFaq.png'

export const BannerWrapper = styled.section`
  align-items: center;
  background-image: url(${landing1}), url(${backgroundBanner});
  background-repeat: no-repeat;
  background-size: 60vh, cover;
  background-position: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  height: 60vh;
  justify-content: center;
  padding-right: 42%;
  width: 100%;

  & h2,
  h3 {
    font-weight: 700;
    line-height: 54px;
  }

  & h2 {
    font-size: 48px;
    color: var(--roxo-riber) !important;
  }

  & h3 {
    font-size: 40px;
    color: var(--branco) !important;
  }

  @media screen and (max-width: 1200px) {
    background-image: url(${backgroundBanner});
    background-size: cover;
    background-position: 100%;
    padding: 0 10px;

    & h2,
    h3 {
      font-weight: 700;
      line-height: 35px;
    }

    & h2 {
      font-size: 30px;
      color: var(--roxo-riber) !important;
    }

    & h3 {
      font-size: 25px;
      color: var(--branco) !important;
    }
  }

  @media screen and (min-width: 1700px) {
    padding-right: 35%;
  }
  @media screen and (min-width: 2200px) {
    padding-right: 32%;
  }
  @media screen and (min-width: 2400px) {
    padding-right: 30%;
  }
`

export const CardSimulacao = styled.div`
  align-items: center;
  background: var(--branco);
  border-radius: 30px;
  box-shadow: 0px 0px 5.41032px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 600px;
  padding: 20px;
  row-gap: 15px;
  width: 100%;

  & .titulos {
    align-items: center;
    display: flex;
    flex-direction: column;

    & label {
      color: var(--verde-riber);
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
    }

    & span {
      color: var(--preto-riber);
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
  }
`

export const InfoProdutosWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  top: -40px;
  position: relative;
  row-gap: 15px;

  @media screen and (max-width: 991px) {
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    top: 0;
  }
`

export const CardInfoProduto = styled.div`
  background: var(--branco);
  box-shadow: 0px 0px 5.41032px rgba(0, 0, 0, 0.18);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  padding: 40px 20px;
  width: 300px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  & .content-infos {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    & .icon {
      color: var(--roxo-riber);
      font-weight: 300;
    }

    & label {
      color: var(--verde-riber);
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      width: 100%;
    }

    & span {
      color: var(--preto-riber);
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      width: 100%;
    }
  }

  & a {
    align-items: center;
    color: var(--roxo-riber);
    column-gap: 10px;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
`

export const AtencaoWrapper = styled.div`
  background: var(--roxo-riber);
  display: flex;
  padding: 20px;

  & div {
    align-items: center;
    color: var(--branco);
    display: flex;
    font-weight: 400;
    font-size: 14px;
    gap: 10px;
    justify-content: center;
    line-height: 24px;
    width: 100%;

    & .icon {
      color: var(--amarelo-riber);
    }
  }
`

export const ApresentacaoEmprestimoWrapper = styled.aside`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 150px;

  & label {
    color: var(--roxo-riber);
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    & span {
      color: var(--verde-riber);
    }
  }

  & p {
    color: var(--preto-riber);
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .primeira-linha,
  .segunda-linha {
    align-items: center;
    display: flex;
    gap: 100px;
    max-width: 800px;
  }

  .primeira-linha {
    text-align: right;
  }

  .segunda-linha {
    text-align: left;
  }

  @media screen and (max-width: 991px) {
    .primeira-linha,
    .segunda-linha {
      flex-direction: column;
      padding: 0 20px;
      text-align: center;
    }
  }
`

export const VantagensWrapper = styled.aside`
  align-items: center;
  background: url(${backgroundBanner}), var(--verdeGradiente-riber);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  display: flex;
  justify-content: space-around;
  padding: 100px 40px;

  & label {
    color: var(--branco);
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 20px;
    text-align: center;

    & span {
      color: var(--roxo-riber);
    }
  }

  & ul {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 600px;

    & li {
      align-items: start;
      color: var(--branco);
      column-gap: 15px;
      display: flex;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      list-style: none;

      span {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 700;
          font-size: 30px;
          text-align: start;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`
export const ImageFraude = styled.img`
  width: 650px;
  height: auto;
  margin: 0 auto;
  justif-content: center;
  display: flex;
  align-items: center;
`

export const EfetuacaoEmprestimoWrapper = styled.article`
  display: flex;

  & div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 50%;

    & img {
      max-width: 500px;
      width: 100%;
    }

    & label {
      color: var(--roxo-riber);
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      width: 100%;

      & span {
        color: var(--verde-riber);
      }
    }

    & ul {
      padding-left: 0;
      & li {
        column-gap: 15px;
        display: flex;
        flex-direction: column;
        list-style: none;

        & .number {
          align-items: center;
          background: var(--verde-riber);
          border-radius: 5px;
          color: var(--branco);
          display: flex;
          height: 35px;
          justify-content: center;
          width: 35px;
        }

        & label {
          color: var(--verde-riber);
          display: flex;
          font-weight: 700;
          font-size: 18px;
          gap: 15px;
          line-height: 28px;
        }

        & span {
          color: var(--preto-riber);
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          padding-left: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    align-items: center;
    flex-direction: column;

    & div {
      width: 100%;
    }
  }
`
export const FaqWrapper = styled.aside`
  background: url(${backgroundFaq}) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  padding: 80px;
  min-height: 650px;

  .img-faq {
    width: 500px;
    object-fit: contain;
  }

  & .faq {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 600px;

    & div {
      flex-direction: column;
      gap: 40px;
    }

    & h3 {
      color: var(--branco);
      font-weight: 700;
      font-size: 27px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .img-faq {
      width: 400px;
    }
  }

  @media screen and (max-width: 991px) {
    background-image: url(${personaFaq}), url(${backgroundFaq});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 600px, cover;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    background-size: 400px, cover;
  }
`

export const CardFaq = styled.div`
  background: var(--light-gray);
  border-radius: 20px;
  display: flex;
  padding: 10px 20px;
  width: 100%;

  & label {
    align-items: center;
    color: var(--preto-riber);
    display: flex;
    font-weight: 400;
    font-size: 18px;
    gap: 10px;
    justify-content: space-between;
    line-height: 32px;

    & .icon {
      min-width: 40px;
    }
  }

  & p {
    color: var(--preto-riber);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
export const SejaParceiroWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 40px;

  & .logo {
    align-items: center;
    display: flex;
    justify-content: center;

    & img {
      width: 250px;
    }
  }

  & .formulario {
    & .icon {
      color: var(--roxo-riber);
      margin-bottom: 20px;
    }

    & h4 {
      color: var(--roxo-riber);
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 0;
    }

    & label {
      color: var(--verde-riber);
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
  }
`

export const RiberPertoWrapper = styled.div`
  & .icon {
    color: var(--roxo-riber);
  }

  & .title {
    color: var(--roxo-riber);
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    width: 100%;

    & span {
      color: var(--verde-riber);
    }
  }
`

export const LocsWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  & .icon {
    color: var(--roxo-riber);
  }
`
